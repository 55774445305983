<template>
    <dialog-wrapper :dialogObj="addDialogObj" @handleClose="handleCancel">
        <el-form ref="form" class="common-form" :model="cancelLeaveForm" :rules="rules" label-width="108px">
            <div class="tip-box">
                <span>确定要对</span>
                <span class="time">{{ `${formData.applicant ? formData.applicant : '--'} ${formData.leaveStartTime} -
                                    ${formData.leaveEndTime}` }}</span>
                <span>的请假进行销假么?</span>
            </div>
            <el-form-item label="实际开始时间" prop="leaveSchoolTime" class="sdxj">
                <div class="flex-box">
                    <el-date-picker popper-class="sdxj" @focus="handleFocus(0)" @blur="getLeaveDuration" :type="dateType"
                        :disabled="disableStartTime" placeholder="请选择实际开始时间" :format="dateFormat"
                        :value-format="valueFormat" :picker-options="startOptions"
                        v-model="cancelLeaveForm.leaveSchoolTime" @change="handleTimeChange"></el-date-picker>
                    <el-tooltip class="item" effect="dark" content="若未填写实际开始时间，实际开始时间默认为请假开始时间。" placement="top-end">
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </div>
            </el-form-item>

            <el-form-item label="实际结束时间" prop="enterSchoolTime" class="sdxj">
                <div class="flex-box">
                    <el-date-picker popper-class="sdxj" @focus="handleFocus(1)" @blur="getLeaveDuration" :type="dateType"
                        :disabled="disableEndTime" placeholder="请选择实际结束时间" :format="dateFormat"
                        :value-format="valueFormat"  @change="handleTimeChange"
                        :picker-options="endOptions" v-model="cancelLeaveForm.enterSchoolTime"></el-date-picker>
                    <el-tooltip class="item" effect="dark" content="若未填写实际结束时间，实际结束时间默认为销假完成时间。" placement="top-end">
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </div>
            </el-form-item>

            <div style="font-size: 14px;color:#2B2E33;padding-left:108px;" v-show="showLeaveDuration">
                <span>请假时长：{{ realDuration }}{{ unit }}</span>
            </div>

            <div :style="{ 'text-align': 'right' }">
                <el-button type="enquiry" @click="handleCancel">取消</el-button>
                <el-button type="primary" @click="handlerAddDialogSave">确定</el-button>
            </div>
        </el-form>
    </dialog-wrapper>
</template>

<script>
import { DialogWrapper } from 'common-local';
import { CoworkingCampusOAVacationManagementModel } from "@/models/CoworkingCampusOAVacationManagement.js";
import { mapState } from 'vuex';
import { debounce } from "common-local";
export default {
    name: "cancelLeaveDialog",
    components: {
        DialogWrapper,
    },
    data() {
        const checkStartTime = (rule, value, callback) => {
            if (this.disableStartTime) {
                return callback();
            }
            if (this.cancelLeaveForm.enterSchoolTime && this.cancelLeaveForm.leaveSchoolTime >= this.cancelLeaveForm.enterSchoolTime) {
                return callback(new Error('开始时间必须早于结束时间'));
            }
            return callback();

        };
        const checkEndTime = (rule, value, callback) => {
            if (this.disableEndTime) {
                return callback();
            }
            if (this.cancelLeaveForm.leaveSchoolTime && this.cancelLeaveForm.leaveSchoolTime >= this.cancelLeaveForm.enterSchoolTime) {
                return callback(new Error('结束时间必须晚于开始时间'));
            }
            return callback();
        };
        return {
            dateFormat: "yyyy-MM-dd HH:mm",
            valueFormat: "yyyy-MM-dd HH:mm",
            dateType: 'datetime',
            addDialogObj: {
                title: '销假',
                dialogVisible: true,
                width: '640px'
            },
            rules: {
                leaveSchoolTime: [
                    { validator: checkStartTime, trigger: ['blur', 'change'] }
                ],
                enterSchoolTime: [
                    { validator: checkEndTime, trigger: ['blur', 'change'] }
                ],
            },
            cancelLeaveForm: {},
            startOptions: {
                selectableRange: '',
                disabledDate: null
            },
            endOptions: {
                selectableRange: '',
                disabledDate: null
            },
            disableStartTime: true,
            disableEndTime: true,
            realDuration: 0,
            unit: '',
            focusPickerIndex: 0,
            rangeIndex: 1
        }
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
        }),
        showLeaveDuration() {
            let flag = this.cancelLeaveForm.leaveSchoolTime && this.cancelLeaveForm.enterSchoolTime;
            return flag
        },
    },
    watch: {

    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.$set(this, 'cancelLeaveForm', this.formData);
            this.disableStartTime = this.cancelLeaveForm.leaveSchoolTime ? true : false;
            this.disableEndTime = this.cancelLeaveForm.enterSchoolTime ? true : false;
            this.setDateSelectRange();
        },
        //给可选中的日期添加点击事件
        addClickEvent(isAdd = true, index = 0) {
            this.focusPickerIndex = index;
            let arrOrigin = new Array(document.getElementsByClassName('available'));
            let arrNew = [];
            for (let i = 0; i < arrOrigin[0].length; i++) {
                arrNew.push(arrOrigin[0][i])
            }
            arrNew = arrNew.filter(item => item.className.indexOf('disabled') == -1)
            arrNew.forEach(item => {
                if (isAdd) {
                    item.addEventListener('click', this.handleDateClick)
                } else {
                    item.removeEventListener('click', this.handleDateClick)
                }
            })
            let form = this.cancelLeaveForm;
            switch (this.rangeIndex) {
                case 1:
                    if (index == 0) {
                        this.handleStartTimeSelectRange(form.leaveStartTime.substr(0, 10))
                    } else {
                        this.handleEndTimeSelectRange(form.leaveEndTime.substr(0, 10))
                    }
                    break
                case 2:
                    this.handleEndTimeSelectRange(form.leaveEndTime.substr(0, 10))
                    break
                case 3:

                    this.handleStartTimeSelectRange(form.leaveStartTime.substr(0, 10))
                    break
            }
        },
        //点击可选日期时触发事件
        handleDateClick(e) {
            let day = e.target.innerText;
            if (day < 10) {
                day = '0' + String(day)
            }
            let arrOrigin = document.querySelectorAll(".sdxj .el-date-picker__header-label");
            const year = arrOrigin[0].innerText.split(' ')[0];
            let month = arrOrigin[1].innerText.split(' ')[0];
            if (month < 10) {
                month = '0' + String(month)
            }
            const date = `${year}-${month}-${day}`;
            this.focusPickerIndex == 0 && (this.handleStartTimeSelectRange(date))
            this.focusPickerIndex == 1 && (this.handleEndTimeSelectRange(date))
        },
        handleFocus(index) {
            try {
                this.$nextTick(() => {
                    this.addClickEvent(true, index);//绑定点击事件
                    document.getElementsByClassName('el-button--text')[0] && document.getElementsByClassName('el-button--text')[0].setAttribute('style', 'display:none')
                    document.getElementsByClassName('el-button--text')[1] && document.getElementsByClassName('el-button--text')[1].setAttribute('style', 'display:none')
                })
            } catch (error) {

            }

        },
        handleDialogClose() {
            this.$emit('handleCancelLeaveClose')
        },
        //确定
        async handlerAddDialogSave() {
            if (await this.checkRequired()) {
                let data = JSON.parse(JSON.stringify(this.cancelLeaveForm))
                //设置时间
                //实际开始时间和实际结束时间给默认值
                data.leaveSchoolTime = data.leaveSchoolTime || this.cancelLeaveForm.leaveStartTime;
                data.enterSchoolTime = data.enterSchoolTime || this.cancelLeaveForm.leaveEndTime;
                data.leaveSchoolTime = data.leaveSchoolTime.length == 19 ? data.leaveSchoolTime : data.leaveSchoolTime + ':00'
                data.enterSchoolTime = data.enterSchoolTime.length == 19 ? data.enterSchoolTime : data.enterSchoolTime + ':00'
                let form = {
                    actualDepartureTime: data.leaveSchoolTime,
                    actualBackTime: data.enterSchoolTime,
                    realDuration: this.realDuration,
                    id: data.id
                }
                const requestObj = new CoworkingCampusOAVacationManagementModel();
                requestObj.handCancelLeave(form).then(res => {
                    if (res.data.code == '200') {
                        this.$message.success('销假成功');
                        this.handleDialogClose();
                    } else if (res.data.code == '202034') {
                        this.$message.error(res.data.msg);
                        this.handleDialogClose();
                    } else {
                        this.$message.error('销假失败！');
                    }
                })
            } else {
                console.log('校验失败');
            }
        },
        //取消
        handleCancel() {
            this.$emit('handleCancel')
        },
        //校验必填
        checkRequired() {
            return new Promise(resolve => {
                this.$refs.form.validate((valid) => {
                    resolve(valid)
                })
            })
        },
        //选择世纪开始时间的事件
        handleStartTimeSelectRange(selectTime) {
            const date = selectTime;
            const today = this.$moment().format('YYYY-MM-DD');
            const curTime = new Date();
            const startTime = this.$moment(new Date(this.cancelLeaveForm.leaveStartTime)).format('YYYY-MM-DD');
            const endTime = this.$moment(this.cancelLeaveForm.enterSchoolTime).format('YYYY-MM-DD');
            let str = '00:00:00 - 23:59:59';
            let s = new Date(this.cancelLeaveForm.leaveStartTime);
            let e = new Date(this.cancelLeaveForm.enterSchoolTime);
            if (!this.disableStartTime && !this.disableEndTime) {//既没有出校也没有入校
                if (date == today) {
                    // 当选择的日期就是当天的时候，这个时候就要限制时间应当小于此时此刻的时分秒
                    str = `'00:00:00 - ${String(curTime.getHours()).padStart(2, '0')}:${String(curTime.getMinutes()).padStart(2, '0')}:${String(curTime.getSeconds()).padStart(2, '0')}'`;
                    let time = String(date.substr(0, 10)) + ` ${String(curTime.getHours()).padStart(2, '0')}:${String(curTime.getMinutes()).padStart(2, '0')}`
                    this.cancelLeaveForm.leaveSchoolTime = time;
                }
                if (date == startTime) {

                    //选择的时间等于假条开始时间
                    str = `'${String(s.getHours()).padStart(2, '0')}:${String(s.getMinutes()).padStart(2, '0')}:${String(s.getSeconds()).padStart(2, '0')} - 23:59:59'`;
                    let time = selectTime + ` ${String(s.getHours()).padStart(2, '0')}:${String(s.getMinutes()).padStart(2, '0')}`
                    this.cancelLeaveForm.leaveSchoolTime = time;
                }
                if (date == today && date == startTime) {
                    //选择的时间等于假条的开始时间和结束时间
                    let e = new Date()
                    str = `'${String(s.getHours()).padStart(2, '0')}:${String(s.getMinutes()).padStart(2, '0')}:${String(s.getSeconds()).padStart(2, '0')} - ${String(e.getHours()).padStart(2, '0')}:${String(e.getMinutes()).padStart(2, '0')}:${String(e.getSeconds()).padStart(2, '0')}'`;
                    let time = selectTime + ` ${String(s.getHours()).padStart(2, '0')}:${String(s.getMinutes()).padStart(2, '0')}`
                    this.cancelLeaveForm.leaveSchoolTime = time;
                }
            }
            if (!this.disableStartTime && this.disableEndTime) {//有入校 没出校
                if (date == endTime) {
                    // 当选择的日期就是实际入校日期时
                    str = `00:00:00 - ${String(e.getHours()).padStart(2, '0')}:${String(e.getMinutes() - 1).padStart(2, '0')}:${String(e.getSeconds()).padStart(2, '0')}`;
                    let time = selectTime + ` ${String(e.getHours()).padStart(2, '0')}:${String(e.getMinutes() - 1).padStart(2, '0')}`
                    this.cancelLeaveForm.leaveSchoolTime = time;
                }
                if (date == startTime) {
                    // 当选择的日期就是假条开始时间
                    str = `'${String(s.getHours()).padStart(2, '0')}:${String(s.getMinutes()).padStart(2, '0')}:${String(s.getSeconds()).padStart(2, '0')} - 23:59:59'`;
                    let time = selectTime + ` ${String(s.getHours()).padStart(2, '0')}:${String(s.getMinutes()).padStart(2, '0')}`
                    this.cancelLeaveForm.leaveSchoolTime = time;
                }
                if (date == endTime && date == startTime) {
                    // 当选择的日期就是假条开始时间和实际入校日期时
                    str = `'${String(s.getHours()).padStart(2, '0')}:${String(s.getMinutes()).padStart(2, '0')}:${String(s.getSeconds()).padStart(2, '0')} - ${String(e.getHours()).padStart(2, '0')}:${String(e.getMinutes() - 1).padStart(2, '0')}:${String(e.getSeconds()).padStart(2, '0')}'`;
                    let time = selectTime + ` ${String(s.getHours()).padStart(2, '0')}:${String(s.getMinutes()).padStart(2, '0')}`
                    this.cancelLeaveForm.leaveSchoolTime = time;
                }
            }
            this.startOptions.selectableRange = str;
            this.$refs.form.validate((valid) => {
            })
        },
        //选择实际结束时间的事件
        handleEndTimeSelectRange(selectTime) {
            const date = selectTime
            const today = this.$moment().format('YYYY-MM-DD');
            const curTime = new Date();//现在的时间
            const startTime = this.$moment(new Date(this.cancelLeaveForm.leaveStartTime)).format('YYYY-MM-DD');
            let str = '00:00:00 - 23:59:59';
            if (!this.disableStartTime && !this.disableEndTime) {
                if (date == today) {
                    // 当选择的日期就是当天的时候
                    str = `'00:00:00 - ${String(curTime.getHours()).padStart(2, '0')}:59:${String(curTime.getSeconds()).padStart(2, '0')}'`;
                    let time = selectTime + ` ${String(curTime.getHours()).padStart(2, '0')}:${String(curTime.getMinutes()).padStart(2, '0')}`
                    setTimeout(() => {
                        this.cancelLeaveForm.enterSchoolTime = time;
                    }, 0)
                }
                if (date == startTime) {
                    let d = new Date(this.cancelLeaveForm.leaveSchoolTime);
                    let e = new Date()
                    str = `'${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes() + 1).padStart(2, '0')}:${String(d.getSeconds()).padStart(2, '0')} - 23:59:59'`;
                    let time = selectTime + ` ${String(e.getHours()).padStart(2, '0')}:${String(e.getMinutes()).padStart(2, '0')}`
                    this.cancelLeaveForm.enterSchoolTime = time;
                }
                if (date == today && date == startTime) {
                    let s = new Date(this.cancelLeaveForm.leaveStartTime);
                    let e = new Date()
                    str = `'${String(s.getHours()).padStart(2, '0')}:${String(s.getMinutes() + 1).padStart(2, '0')}:${String(s.getSeconds()).padStart(2, '0')} - ${String(e.getHours()).padStart(2, '0')}:59:${String(e.getSeconds()).padStart(2, '0')}'`;
                    let time = selectTime + ` ${String(e.getHours()).padStart(2, '0')}:${String(e.getMinutes()).padStart(2, '0')}`
                    this.cancelLeaveForm.enterSchoolTime = time;
                }
            }

            if (this.disableStartTime && !this.disableEndTime) {
                const startTime = this.$moment(new Date(this.cancelLeaveForm.leaveSchoolTime)).format('YYYY-MM-DD');
                if (date == today) {
                    // 当选择的日期就是当天的时候
                    str = `'00:00:00 - ${String(curTime.getHours()).padStart(2, '0')}:59:${String(curTime.getSeconds()).padStart(2, '0')}'`;
                    let time = selectTime + ` ${String(curTime.getHours()).padStart(2, '0')}:${String(curTime.getMinutes()).padStart(2, '0')}`
                    this.cancelLeaveForm.enterSchoolTime = time;
                }
                if (date == startTime) {
                    // 当选择的日期就是实际出校日期时
                    let s = new Date(this.cancelLeaveForm.leaveSchoolTime)
                    str = `'${String(s.getHours()).padStart(2, '0')}:${String(s.getMinutes() + 1).padStart(2, '0')}:${String(s.getSeconds()).padStart(2, '0')} - 23:59:59'`;
                    let time = selectTime + ` ${String(s.getHours()).padStart(2, '0')}:${String(s.getMinutes() + 1).padStart(2, '0')}`
                    this.cancelLeaveForm.enterSchoolTime = time;
                }
                if (date == today && date == startTime) {
                    let s = new Date(this.cancelLeaveForm.leaveSchoolTime);
                    let e = new Date()
                    str = `'${String(s.getHours()).padStart(2, '0')}:${String(s.getMinutes() + 1).padStart(2, '0')}:${String(s.getSeconds()).padStart(2, '0')} - ${String(e.getHours()).padStart(2, '0')}:59:${String(e.getSeconds()).padStart(2, '0')}'`;
                    let time = selectTime + ` ${String(s.getHours()).padStart(2, '0')}:${String(s.getMinutes()).padStart(2, '0')}`
                    this.cancelLeaveForm.leaveSchoolTime = time;
                }

            }
            console.log('选择范围====', str);
            this.endOptions.selectableRange = str;
            this.$refs.form.validate((valid) => {
            })
            this.$forceUpdate();
        },
        setDateSelectRange() {
            //设置开始时间
            if (!this.disableStartTime && !this.disableEndTime) {//没有出校记录也没有入校记录 开始时间小于当前时间
                this.setRange(1);
                this.rangeIndex = 1;
            }
            if (this.disableStartTime && !this.disableEndTime) {//有出校记录没有入校记录  实际出校时间（分钟+1）~用户的当前时间
                this.setRange(2);
                this.rangeIndex = 2;
            }
            if (!this.disableStartTime && this.disableEndTime) {//没有出校记录 有入校记录 选择实际出校时间之前的时间
                this.setRange(3);
                this.rangeIndex = 3;
            }
        },
        setRange(type) {
            switch (type) {
                case 1:
                    this.startOptions.disabledDate = (time) => {
                        let st = new Date(this.formData.leaveStartTime.substr(0, 10)).getTime();//请假开始日期
                        return (time.getTime() + (24 * 60 * 60 * 1000)) < st || Date.now() < time.getTime();
                    }
                    this.endOptions.disabledDate = (time) => {
                        let st = new Date(this.formData.leaveStartTime.substr(0, 10)).getTime();//请假开始日期
                        return (time.getTime() + (24 * 60 * 60 * 1000)) < st || Date.now() < time.getTime();
                    }
                    //实际开始时间和实际结束时间给默认值
                    this.cancelLeaveForm.leaveSchoolTime = this.cancelLeaveForm.leaveStartTime
                    this.cancelLeaveForm.enterSchoolTime = this.$moment().format('YYYY-MM-DD HH:mm');
                    break;
                case 2:
                    this.endOptions.disabledDate = (time) => {
                        let st = new Date(this.formData.leaveSchoolTime.substr(0, 10)).getTime();//请假开始日期
                        return (time.getTime() + (24 * 60 * 60 * 1000)) < st || Date.now() < time.getTime();
                    }
                    //实际开始时间和实际结束时间给默认值
                    this.cancelLeaveForm.enterSchoolTime = this.$moment().format('YYYY-MM-DD HH:mm');
                    break;
                case 3:
                    this.startOptions.disabledDate = (time) => {
                        let st1 = new Date(this.formData.enterSchoolTime.substr(0, 10)).getTime();//实际出校时间
                        let st2 = new Date(this.formData.leaveStartTime.substr(0, 10)).getTime();//请假开始时间
                        return time.getTime() > st1 || (time.getTime() + (24 * 60 * 60 * 1000)) < st2;;
                    }
                    //实际开始时间和实际结束时间给默认值
                    this.cancelLeaveForm.leaveSchoolTime = this.cancelLeaveForm.leaveStartTime
                    break;
            }
            this.startOptions.selectableRange = '00:00:00 - 23:59:59';
            this.endOptions.selectableRange = '00:00:00 - 23:59:59';
            this.getLeaveDuration();
        },
        //获取请假时长
        getLeaveDuration: debounce(function () {
            let data = this.cancelLeaveForm;
            if (data.leaveSchoolTime && data.enterSchoolTime && data.enterSchoolTime > data.leaveSchoolTime) {
                data.leaveSchoolTime = data.leaveSchoolTime.length == 19 ? data.leaveSchoolTime : data.leaveSchoolTime + ':00'
                data.enterSchoolTime = data.enterSchoolTime.length == 19 ? data.enterSchoolTime : data.enterSchoolTime + ':00'
                const requestObj = new CoworkingCampusOAVacationManagementModel();
                let form = {
                    actualDepartureTime: data.leaveSchoolTime,
                    actualBackTime: data.enterSchoolTime
                };
                requestObj.getVacationDurationHandleCancel(form).then((res) => {
                    if (res.data.code == '200') {
                        this.realDuration = res.data.data.realDuration;
                        this.unit = res.data.data.unit
                    }
                })
            }
        }, 750),
        handleTimeChange() {
            this.$refs.form.validate((valid) => {
            })
        }
    }
};
</script>

<style scoped lang="scss">
::v-deep {
    .el-dialog__body {
        margin-top: 0px;
        padding-top: 15px;
    }

    .common-form {
        .tip-box {
            margin-bottom: 25px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #2B2E33;

            .time {
                color: #3C7FFF;
            }
        }

        .flex-box {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .el-date-editor {
                width: 94% !important;
            }

            .el-icon-question {
                color: #A0A0A0;
            }
        }
    }
}</style>
